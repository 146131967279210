import AppBar from "./AppBar";
import hero from "../assets/hero-1.png";
import heroSecond from "../assets/hero-bg.jpg";
import playButton from "../assets/GetItOnGooglePlay_Badge_Web_color_English.png";
import divider from "../assets/v-divider.png";
import about1 from "../assets/about-1.png";
import about2 from "../assets/about-2.png";
import about3 from "../assets/about-3.png";
import iphone from "../assets/iphone-bg.png";
import plants from "../assets/plants-bg.jpg";
import arrowDown from "../assets/arrow_down.png";
import address from "../assets/address.png";
import phone from "../assets/phone.png";
import email from "../assets/email.png";
import trophy from "../assets/loveshore-memento.png";
import docushot from "../assets/docushot.png";
import Footer from "./Footer";

const Home = () => {
    return (
        <>
            <AppBar />
            <div style={{ display: "flex", flexDirection: "column" }}>
                <div className="container" style={{ maxWidth: '1600px' }}>
                    <div className="hero hero-margin">
                        <div className="hero-content" style={{ top: '20px', left: '0', }}>
                            <h2>LOVESHORE</h2>
                            <h1>BLACK DRONGO</h1>
                            <p className="title">International Short Film Festival</p>
                            <p className="title">First Half 2025</p>
                            <span>in association with</span>
                            <div><img src={docushot} alt="Documentary Short FilmMakers & Technicians Association"
                                title="Documentary Short FilmMakers & Technicians Association"
                                style={{ height: '50px' }} /></div>
                            <div id="text-slider-container">
                                <div className="text-slider">
                                    <div className="slider-item">1<sup>st</sup> Prize 1 Lakh</div>
                                    <div className="slider-item">Best Short Film</div>
                                    <div className="slider-item">പി കെ റോസി പുരസ്‌ക്കാരം</div>
                                    <div className="slider-item">Best Audience Poll Short Film</div>
                                    <div className="slider-item">Best Students Film</div>
                                    <div className="slider-item">Best Musical Film / Album</div>
                                    <div className="slider-item">Best Mobile Film</div>
                                    <div className="slider-item">Best Reels Film</div>
                                </div>
                            </div>
                            <img src={trophy} alt="1st Prize" style={{ height: '150px' }} />
                            <p>Starting<br/>October 10, 2024</p>
                        </div>
                        <div className="hero-image">
                            <a href="/loveshoreblackdrongo"><img src={hero} /></a>
                        </div>
                    </div>
                </div>

                <div className="white-box" style={{ borderTopLeftRadius: '50px', borderTopRightRadius: '50px' }}>
                    <div className="container" style={{ maxWidth: '1600px' }}>
                        <div className="content-margin">
                            <div className="lc-card">
                                <div className="row g-5">
                                    <div className="col-md-6">
                                        <div className="center-small">
                                            <div style={{ fontSize: '32px' }}>Watch LOVESHORE</div>
                                            <div style={{ fontSize: '20px' }}>Download our Android App Now</div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="center-small align-right">
                                            <a href="https://play.google.com/store/apps/details?id=com.affinity.loveshore2024"><img src={playButton} style={{ width: '100%', maxWidth: '200px' }} /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="white-box">
                    <div className="container" style={{ maxWidth: '1600px' }}>
                        <div className="content-margin">
                            <div className="row g-5">
                                <div className="col-md-4">
                                    <div style={{ fontSize: '48px', fontWeight: '600' }}>LOVESHORE</div>
                                    <div style={{ fontSize: '24px', color: '#616161' }}>Media Communications</div>
                                    <p style={{ fontSize: '16px', color: '#616161' }}>An Innovative Cloud Television Platform</p>
                                    <button type="button" className="btn btn-outline-dark">Read More</button>
                                </div>
                                <div className="col-md-8">
                                    <img src={heroSecond} alt="LoveShore Media Communications" style={{ width: '100%', borderRadius: '25px' }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="white-box">
                    <div className="container" style={{ maxWidth: '1600px' }}>
                        <div className="content-margin text-center">
                            <div style={{ paddingTop: '60px', marginBottom: '10px' }}>
                                <h4>A SMART TELEVISION</h4>
                            </div>
                            <div style={{ marginBottom: '10px' }}>
                                <img src={divider} alt="Divider" />
                            </div>
                            <div style={{ maxWidth: '762px', marginLeft: 'auto', marginRight: 'auto', paddingBottom: '60px' }}>
                                <p style={{ color: '#616161' }}>Loveshore Media is a cloud category television. It is a software platform visualized with the help of devices on the customer premises or setup box. It enables pay TV operators and other video service providers to bring innovative user interfaces and online video experiences through specialized apps such as social media to existing and nearby cable television and IPTV set-top boxes, connected consumer electronic devices and the PlayStore app store.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="black-box" id="aboutus">
                    <div className="container" style={{ maxWidth: '1600px' }}>
                        <div className="content-margin text-center">
                            <div style={{ paddingTop: '60px', marginBottom: '10px', color: '#fff' }}>
                                <h4>ABOUT US</h4>
                            </div>
                            <div style={{ marginBottom: '10px' }}>
                                <img src={divider} alt="Divider" />
                            </div>
                            <div className="row aboutus">
                                <div className="col-lg-4">
                                    <div className="card h-100 text-center">
                                        <div className="card-body">
                                            <img src={about1} alt="About Us" style={{ marginBottom: '20px' }} />
                                            <h5 className="card-title">Family Entertainment</h5>
                                            <p className="card-text">Loveshore Media brings family entertainment right into your communication device You can watch Love Shore Media on your mobile phone, laptop, tablet, computer and smart television.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="card h-100">
                                        <div className="card-body">
                                            <img src={about2} alt="About Us" style={{ marginBottom: '20px' }} />
                                            <h5 className="card-title">Real-time Broadcast</h5>
                                            <p className="card-text">Loveshore Media provides entertainment video programs only under the terms of the Copyright act Loveshore Media provides programs in real-time broadcast style like a worldwide satellite television channel through our app.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="card h-100">
                                        <div className="card-body">
                                            <img src={about3} alt="About Us" style={{ marginBottom: '20px' }} />
                                            <h5 className="card-title">The Network</h5>
                                            <p className="card-text">Loveshore media programs are provided free to air television network. No subscription. Band-width coverage area is available depending on Loveshore Media Worldwide coverage area. Perfection will also be achieved according to your network signal strength.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="black-box">
                    <div className="container" style={{ maxWidth: '1600px' }}>
                        <div className="content-margin text-center white-box" style={{ padding: '40px 0', borderRadius: '25px' }}>
                            <div className="row">
                                <div className="col-lg-6">
                                    <img src={iphone} alt="Cloud Television" style={{ width: '100%', borderRadius: '25px' }} />
                                </div>
                                <div className="col-lg-6">
                                    <h4>CLOUD TV</h4>
                                    <div style={{ marginBottom: '10px' }}>
                                        <img src={divider} alt="Divider" />
                                    </div>
                                    <div className="p-4">
                                        <p style={{ color: '#616161' }}>
                                            Cloud TV is a software platform that visualizes with the help
                                            of a mobile phone, laptop, tablet, smart TV or set-up box.
                                            It enables pay TV operators and other video service providers
                                            to deliver better user interfaces, online video experiences
                                            and better visuals to family audiences, to existing and nearby
                                            cable television and IP TV set-top boxes and connected consumer
                                            electronics devices.
                                            Cloud TV, a product of Silicon Valley software company Active Video,
                                            is available on more than a million devices. Now it is coming to
                                            India in the next generation. Interactive visuals invite you to
                                            travel through the realms of knowledge and entertainment.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ minHeight: '400px', textAlign: "center", padding: '40px 20px', backgroundImage: `url(${plants})`, backgroundPosition: "center", backgroundSize: "cover", borderTopRightRadius: '25px', borderTopLeftRadius: '25px' }}>
                    <h3 style={{ color: '#ffffff' }}>A New Age TV Network</h3>
                    <h4 style={{ color: '#ffffff' }}>Digital Infotainment at your convenience</h4>
                    <img src={arrowDown} alt="" style={{ marginTop: '30px' }} />
                    <div style={{ maxWidth: '762px', marginLeft: 'auto', marginRight: 'auto', paddingTop: '60px' }}>
                        <p style={{ color: '#ffffff' }}>One has to rely on the companies that market cloud TV to start broadcasting at a low cost.
                            Depending on India's potential, some companies have started operations here. Today they are all set
                            to launch a channel and take it to the global market. It is easy for us like any other country
                            It is a method of broadcasting 24-hour continuous programs according to schedule like a television
                            channel.</p>
                    </div>
                </div>
                <div className="white-box" id="career">
                    <div className="container" style={{ maxWidth: '1600px' }}>
                        <div className="content-margin text-center">
                            <div style={{ paddingTop: '60px', marginBottom: '10px' }}>
                                <h4>Career</h4>
                            </div>
                            <div style={{ marginBottom: '10px' }}>
                                <img src={divider} alt="Divider" />
                            </div>
                            <div style={{ maxWidth: '762px', marginLeft: 'auto', marginRight: 'auto', paddingBottom: '60px' }}>
                                <p style={{ color: '#616161' }}>Love Shore Media is inviting the following entries to start broadcasting completely free of charge with new technological excellence Telefilms, serials, video albums, reality shows, folk arts, documentaries, comedy shows.</p>
                                <p style={{ color: '#616161' }}>Contact us for more information.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="black-box">
                    <div className="container" style={{ maxWidth: '1600px' }}>
                        <div className="content-margin text-center">
                            <div style={{ paddingTop: '60px', marginBottom: '10px' }}>
                                <h4 style={{ textTransform: "uppercase", color: '#ffffff' }}>Subscribe to stay in the loop</h4>
                            </div>
                            <div style={{ marginBottom: '10px' }}>
                                <img src={divider} alt="Divider" />
                            </div>
                            <div style={{ maxWidth: '500px', marginLeft: 'auto', marginRight: 'auto', paddingBottom: '60px' }}>
                                <div className="row g-3" style={{ textAlign: "center" }}>
                                    <div className="col-10">
                                        <input type="email" className="form-control" placeholder="Enter your email address" />
                                    </div>
                                    <div className="col-2">
                                        <button className="btn btn-outline-light">Send</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="white-box" id="getintouch">
                    <div className="container" style={{ maxWidth: '1600px' }}>
                        <div className="content-margin text-center">
                            <div style={{ paddingTop: '60px', marginBottom: '10px' }}>
                                <h4 style={{ textTransform: "uppercase", color: '#000000' }}>DROP US A LINE</h4>
                            </div>
                            <div style={{ marginBottom: '10px' }}>
                                <img src={divider} alt="Divider" />
                            </div>
                            <div style={{ maxWidth: '762px', marginLeft: 'auto', marginRight: 'auto', paddingBottom: '60px' }}>
                                <div className="row g-3 contactus" style={{ textAlign: "center" }}>
                                    <div className="col-md-4">
                                        <div className="card h-100">
                                            <div className="card-body">
                                                <img src={address} alt="About Us" style={{ marginBottom: '20px', width: '100%', maxWidth: '210px' }} />
                                                <h5 className="card-title">Address</h5>
                                                <p className="card-text fs-small">XII/610, Chilanka Junction,<br />
                                                    Vadapanapilly, Thrissur, Kerala.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="card h-100">
                                            <div className="card-body">
                                                <img src={phone} alt="About Us" style={{ marginBottom: '20px', width: '100%', maxWidth: '210px' }} />
                                                <h5 className="card-title">Phone</h5>
                                                <p className="card-text fs-small">+91 854 761 8676<br />
                                                    +91 974 430 7512</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="card h-100">
                                            <div className="card-body">
                                                <img src={email} alt="About Us" style={{ marginBottom: '20px', width: '100%', maxWidth: '210px' }} />
                                                <h5 className="card-title">EMail</h5>
                                                <p className="card-text fs-small">info@loveshoremedia.in</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default Home;